// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #919eab3d !important',
          whiteSpace: 'nowrap',
          backgroundColor: theme.palette.background.cell,
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover
            }
          },
          '&:first-of-type': {
            color: theme.palette.text.secondary
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          lineHeight: '2',
          borderBottom: 'inherit',
          padding: '0 0 0 10px',
          color: 'inherit'
        },
        head: {
          paddingLeft: theme.spacing(3),
          color: theme.palette.text.primary,
          borderTop: '1px solid #919eab3d !important',
          paddingTop: '.25rem',
          paddingBottom: '.25rem',
          backgroundColor: theme.palette.background.neutral
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`
        },
        body: {
          '&:first-of-type': {
            paddingLeft: theme.spacing(3)
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(3)
          },
          '& img': {
            display: 'initial',
            width: '22px',
            verticalAlign: 'text-bottom',
            paddingRight: '5px'
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`
        },
        toolbar: {
          height: 64
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius
          }
        },
        selectIcon: {
          width: 20,
          height: 20,
          marginTop: 2
        }
      }
    }
  };
}

import { useState, useEffect } from 'react';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { useDispatch } from '../redux/store';
import SvgIconStyle from './SvgIconStyle';
import { getBoard, setActive } from '../redux/slices/coins';

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/svgs/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  dashboard: getIcon('bar-dashboard'),
  currency: getIcon('bar-currency'),
  best: getIcon('bar-best'),
  deps: getIcon('bar-deps')
};

const BottomNavigationStyled = styled(BottomNavigation)(({ theme }) => ({
  '&.MuiBottomNavigation-root': {
    backgroundColor: theme.palette.background.darker
  },
  '& .MuiBottomNavigationAction-root': {
    paddingBottom: '5px'
  },
  '& .MuiBottomNavigationAction-label': {
    paddingTop: '2px'
  },
  '& .Mui-selected': {
    paddingBottom: '1px',
    '&.MuiBottomNavigationAction-root': {
      borderBottom: '3px solid #028b93 !important',
      marginBottom: '0px',
      filter: 'brightness(1.5)'
    },
    '&.MuiBottomNavigationAction-label': {
      fontSize: '0.75rem'
    }
  }
}));

export default function FixedBottomNavigation() {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  useEffect(() => {
    console.log('GET Board!');
    dispatch(getBoard());
  }, [dispatch]);

  return (
    <Paper
      sx={{ position: 'fixed', maxWidth: '100%', bottom: 0, left: 0, right: 0, borderTop: '1px solid #1f2838' }}
      elevation={3}
    >
      <BottomNavigationStyled
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          dispatch(setActive(newValue));
        }}
      >
        <BottomNavigationAction value="home" label="Home" icon={ICONS.dashboard} />
        <Divider orientation="vertical" variant="middle" flexItem />
        <BottomNavigationAction value="coins" label="Coins" icon={ICONS.currency} />
        <Divider orientation="vertical" variant="middle" flexItem />
        <BottomNavigationAction value="depositories" label="Depositories" icon={ICONS.deps} />
        <Divider orientation="vertical" variant="middle" flexItem />
        <BottomNavigationAction value="results" label="Results" icon={ICONS.best} />
      </BottomNavigationStyled>
    </Paper>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const defaultTable = 'coins';

const initialState = {
  isLoading: false,
  error: false,
  activeTable: defaultTable,
  table: [],
  tables: []
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    getBoardSuccess(state, action) {
      state.isLoading = false;
      console.log('===', action);
      state.tables = action.payload;
      state.table = state.tables[state.activeTable];
    },

    // GET BOARD
    setActiveBoard(state, action) {
      state.isLoading = false;
      console.log('===', action);
      state.activeTable = action.payload;
      state.table = state.tables[action.payload || defaultTable];
    },

    // UPDATE DATA
    updateData(state, action) {
      const { newTable } = action.payload;
      state.table = newTable;
      console.log(newTable);
      state.tables[state.activeTable] = newTable;
    }
  }
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getBoard() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('http://digrr.com/yielder/api/get_cdr.php?xz=3');
      dispatch(slice.actions.getBoardSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setActive(board) {
  return async (dispatch) => {
    dispatch(slice.actions.setActiveBoard(board));
  };
}

export function updateData(newTable) {
  return async (dispatch) => {
    dispatch(slice.actions.updateData(newTable));
  };
}
